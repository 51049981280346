//登录注册相关服务
import axios from "axios";
const md5=require("blueimp-md5");
const adminMenu = require("../layout/adminMenu")

const Parse=window.Parse;

//保存当前用户的全局变量
let gUser = {
    username: "未登录",
};

//从localStorage中回复上次登录的用户
if(window.localStorage["gUser"]){
    const savedUser=JSON.parse(window.localStorage["gUser"]);
    Object.assign(gUser,savedUser);
}

export default class User {
    static async login(username, password) {
        User.cleanStatus();//清除上一个用户残留的状态
        const salt="lims_v5";
        const password_md5 = md5(password+salt);
        const user= await axios.post('/user/login', {username, password_md5})
            .then(res=>res.data);
        //连接Parse User
        const parseUser = new Parse.User();
        const myAuthData = {
            id: username,
            password_md5
        };
        await  parseUser.linkWith('lims_auth', { authData: myAuthData });
        console.log("连接Parse用户成功");
        console.log(Parse.User.current().toJSON());
        gUser=user;//登录成功，把用户信息保存到gUser
        User.setExpire();//设置当前用户的过期时间
        window.localStorage["gUser"] = JSON.stringify(gUser);//保存到localStorage以便关闭浏览器时仍不丢失
    }

    static logout(){
        gUser={
            username: "未登录",
        };
        delete window.localStorage["gUser"];
        window.location.hash = '/login';
    }

    //清除上一个用户残留的状态
    static async cleanStatus(){
        window.gResourceManagerStore=null;//清除资源管理器状态
        window.gModalSelectStore = null;//清除课程
        window.gModalDoubleSelectStore = null;//清除课程和教学班级
        if(Parse.User.current()){
            try{
                await Parse.User.logOut();//如果上一个用户没退出，先退出
            }catch (e) {
                console.error(e)
            }

        }
    }

    //设置过期时间，在路由切换时检测到过期跳到登录页面
    static setExpire(username) {
        const now = Date.now();
        const expire = now + 8 * 60 * 60 * 1000; //8小时
        // const expire=now+8*1000; //8秒测试
        gUser.expire = expire;
    }

    //用户登录是否过期
    static isExpired() {
        return (!gUser.expire || Date.now() > gUser.expire)
    }

    static getCurrentUserName() {
        return gUser.username;
    }
    static getCurrentUserid() {
        return gUser.userid;
    }
    static getCurrentUserRole() {
        return gUser.role;
    }

    static getCurrentUserMenu() {
        if(gUser.isAdmin) {
            return adminMenu
        }
    }

    static getCurrentUserShowName() {
        return gUser.showName;
    }
    static getCurrentStudentNumber() {
        return gUser.studentNumber;
    }
    static getIsAssistant() {
        return gUser.isAssistant;
    }
    static getCurrentUser() {
        return gUser;
    }
}
