import React from "react";
import { Layout, Menu, Row, Col, Avatar,Dropdown } from 'antd';
import UserService from "../services/User";
import DataMenu from "../components/common/Menu/Menu";
import Breadcrumbs from "./BreadcrumbsFromPath"
require("./BasicLayout.css");
const config = window.config
const { Header, Content, Footer } = Layout;

const userMenu = (
    <Menu>
        <Menu.Item key="logout">
            <a  href="#/logout">
                退出
            </a>
        </Menu.Item>
        {/*<Menu.Item key={'changeInfo'}>*/}
        {/*    <a href={'#/change_info'}>修改信息</a>*/}
        {/*</Menu.Item>*/}
        {/*<Menu.Item key={'changePassword'}>*/}
        {/*    <a href={'#/change_password'}>修改密码</a>*/}
        {/*</Menu.Item>*/}
    </Menu>
);

export default function BasicLayout({children}){
    return (
        <div className={'out_layout'}>
            <Layout className={'layout'}>
                <Header style={{background:'#DEE4FF'}}>
                    <Row>
                        <Col span={20}>
                            <div className={'logo'}>
                                <img src={config.logo} />
                                <span className={'name'}>{config.name}</span>
                            </div>
                            <DataMenu
                                data={UserService.getCurrentUserMenu()}
                                mode={'horizontal'}
                                defaultSelectedKeys={['2']}
                            />
                        </Col>
                        <Col span={4}>
                            <div className={'header_right'}>
                                <Dropdown overlay={userMenu}>
                                    <a className={'ant-dropdown-link'}>
                                        <Avatar style={{backgroundColor:'#b3b7d0', verticalAlign:'middle'}} size={'large'}>
                                            {UserService.getCurrentUserShowName()}
                                        </Avatar>
                                    </a>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>
                </Header>
                <div style={{padding: '0 50px', backgroundColor: '#fff'}}>
                    <Breadcrumbs />
                </div>

                <Content style={{padding: '0 50px',backgroundColor:'#fff'}}>
                    <div style={{background:'#fff', minHeight: 600}}>
                        {children}
                    </div>
                </Content>
                <Footer style={{textAlign: 'center',background: 'none'}}>OLAB ©2020 Created by Limsolution </Footer>
            </Layout>
        </div>
    )
}