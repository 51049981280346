import {observable} from "mobx";

class BreadcrumbsStoreStore {
    @observable currentCourse = "当前课程";
    @observable courseClass = {};
    @observable course = {};
    @observable boxName;//当前作业箱名称
    @observable nextReportId;//下一份作业ID
    @observable nextReportName;//下一份作业名称
    @observable courseName;//课程实验名称
}

const breadcrumbsStore = new BreadcrumbsStoreStore();
export default breadcrumbsStore;