import React from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  withRouter
} from "react-router-dom";
import { ConfigProvider} from "antd"
// 由于 antd 组件的默认文案是因为，所以需要修改为中文
import zhCN from 'antd/lib/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
import loadable from "@loadable/component";
import BasicLayout from "./layout/BasicLayout";
import UserService from "./services/User";
moment.locale('zh-cn')

const ChangeTracker = withRouter(({match, location, history}) => {
    console.log(location.pathname, location.state);
    if (location.pathname !== '/login'&&!location.pathname.startsWith('/report/')&&location.pathname !== '/register'&&location.pathname !== '/reset'&&location.pathname !== '/a/input') {
        if (UserService.isExpired()) {
            console.log("登录时间过期！");
            window.location.hash = '/login';
        }
    }
    return false;
});


const Loading=()=><div>加载中...</div>
const NotMatch=()=><div>NotMatch</div>

const Login = loadable(() => import("./routes/Login"), {fallback: <Loading /> });
const Logout = loadable(() => import("./routes/Logout"), {fallback: <Loading /> });
const Home=()=><div>Home</div>

const AdminBindList = loadable(() => import("./routes/admin/BindList"), {fallback: <Loading/>})
const OnlineDevices = loadable(() => import("./routes/admin/OnlineDevices"), {fallback: <Loading/>});

// 教师实验箱管理
const ExperimentBox = loadable(() => import("./routes/admin/ExperimentBox"), {fallback: <Loading/>})
// 批量导入教师实验箱
const ImportTeacherBox = loadable(() => import("./routes/admin/ImportTeacherBox"),{fallback:<Loading />})

const DataStatistics = loadable(() => import("./routes/admin/DataStatistics"), {fallback: <Loading/>});

// 平台设置
const College = loadable(() => import("./routes/admin/College"),{fallback:<Loading />})

const AdminInput = loadable(() => import("./routes/admin/InputText"), {fallback: <Loading/>});
export default  ()=>{
  return (
    <ConfigProvider locale={zhCN}>
    <Router>
        <ChangeTracker/>
        <Switch>
            <Route exact path="/login" component={Login}/>
            <Route exact path="/logout" component={Logout}/>
            <BasicLayout>
                <Switch>
                    <Route path="/a/bind_list" component={AdminBindList}/>
                    <Route path="/a/online_devices" component={OnlineDevices}/>

                    {/*教师实验箱管理*/}
                    <Route path="/a/input" component={AdminInput}/>
                    <Route path="/a/experiment_box" component={ExperimentBox}/>
                    <Route exact path="/a/i_teacher_box" component={ImportTeacherBox} />
                    <Route path="/a/data_statistics" component={DataStatistics}/>
                    <Route path={"/a/college"} component={College} />
                    <Route exact path="/"><Home /></Route>
                    <Route path="/*" component={NotMatch} />
                </Switch>
            </BasicLayout>
        </Switch>
    </Router>
    </ConfigProvider>
  );
}