//保存当前用户的全局变量
import axios from "axios";
import ConfigService from "./ConfigService";

let gUser = {};

export default class Login {
    constructor(){
        Login.getGUserFromLocalStorage();
    }
    static getGUserFromLocalStorage(){
        const sgUser=localStorage.gUser;
        if(sgUser){
            gUser=JSON.parse(sgUser);
        }
    }
    static async login(username, password) {
        Login.cleanStatus();
        return await axios.post('/api/v1/login', {username, password}).then(res=>{
            const user=res.data;
            if(user){
                gUser=user;
                Login.setExpire(gUser);
                localStorage.gUser=JSON.stringify(gUser);
            }
            ConfigService.refresh(); //登录时刷新配置信息
        }).catch(e=>{
            throw (e && e.response && e.response.data) || e;
        });
    }
    static logout(){
        gUser = {};
        Login.cleanStatus();
        delete localStorage.gUser;
    }

    static cleanStatus(){
        //清除上一个用户残留的状态
        window.gResourceManagerStore=null;//清除资源管理器状态
        window.gModalSelectStore = null;//清除课程
        window.gModalDoubleSelectStore = null;//清除课程和教学班级

    }

    static setExpire(gUser) {
        const now = Date.now();
        const expire = now + 8 * 60 * 60 * 1000; //8小时
        // const expire=now+8*1000; //8秒测试
        gUser.expire = expire;
    }

    static isExpired() {
        if(!gUser||!gUser.expire){
            Login.getGUserFromLocalStorage();
        }
        return (!gUser.expire || Date.now() > gUser.expire)
    }

    static getCurrentUserName() {
        return gUser.username|| "未登录";
    }
    static getCurrentUserId() {
        return gUser.objectId|| "未登录";
    }
    static getCurrentUserRole() {
        return gUser.role|| "student";
    }

    static getCurrentUserShowName() {
        return gUser.showName|| "";
    }
    static getCurrentUserSchool() {
        return gUser.school||'';
    }
    static getCurrentStudentNumber() {
        return gUser.studentNumber||'';
    }
    static getCurrentUser() {
        return gUser;
    }
}
