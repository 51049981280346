const menuData = [
    {
        "title": "系统对接",
        "children": [
            // {
            //     "title": "口袋实验箱绑定管理",
            //     "url": "/a/bind_list",
            // },
            {
                "title": "在线设备",
                "url": "/a/online_devices",
            },
            {
                "title": "教师实验箱管理",
                "url": "/a/experiment_box"
            },
            {
                "title": "硬木配置",
                "url": "/a/input"
            }
        ]
    },
    {
        "title": "数据统计",
        "url": "/a/data_statistics",
    },
    {
        "title": "平台设置",
        "url": "/a/college"
    }
]

module.exports = menuData